<template>
	<v-card>
		<v-card-text>
			<v-row v-for="(connection, i) in connections" :key="i" dense>
				<v-col cols="9" class="text-left">	
					{{connection.input.name}} to {{connection.output.name}}
				</v-col>
				<v-col cols="3">
					<v-dialog v-model="dialog" :max-width="600" persistent>
						<template v-slot:activator="{ on, attrs }">
							<v-btn v-show="item.enabled" x-small text color="primary" v-bind="attrs" @click="dialog = !dialog">
								Disable
							</v-btn>
						</template>
						<ConnectionDisable :connection="connection" @close="dialog=false"/>
					</v-dialog>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>

import ConnectionDisable from '@/components/connections/ConnectionDisable';
import { mapGetters } from 'vuex';
import _ from 'lodash';


export default {
	name: "ConnectionsInfo",

	components:{
		ConnectionDisable
	},

	watch: {
		dialog(value)
		{
			if(value)
			{
				//close external v-menu when dialog is launched
				this.$emit('close');
			}
		}
	},

	props: {
		item: Object,
		schema: Object
	},
	computed: {
		...mapGetters('configuration', ['getIntegration']),
		connections()
		{
			return _.filter(this.item.connections, ['cls', this.schema.cls]);
		}
	},
	methods: {
		
	},

	data: () => ({
		dialog: false
	}),
};
</script>
